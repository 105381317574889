.dropdown-menu {
  background-color: #fff !important;
  border-radius: 4px !important;
  border: solid 1px rgba(71, 70, 71, 0.26) !important;
  min-width: 150px !important;
  width: min-content !important;
  white-space: nowrap;
  min-height: 40px;
}

.dropdown-menu-text {
  font-size: 14px;
  color: #333333;
}
